<template>
  <div class="user">
    <div class="content">
      <div class="left" v-if="userInfo">
        <el-dropdown class="header" placement="right">
          <div>
            <img
              :src="
                userInfo.picUrl
                  ? userInfo.picUrl
                  : require('../../assets/normal_header.jpg')
              "
              alt=""
            />
            <p>{{ userInfo.name ? userInfo.name : userInfo.mobile }}</p>
            <i class="el-icon-edit-outline edit_icon"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              style="
                width: 150px;
                height: 40px;
                text-align: center;
                position: relative;
              "
            >
              <el-upload
                action=""
                class="upload-demo"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="changeFile"
              >
                <el-button style="border: none">上传头像</el-button></el-upload
              >
            </el-dropdown-item>
            <el-dropdown-item
              style="
                width: 150px;
                height: 40px;
                text-align: center;
                position: relative;
              "
            >
              <el-image
                v-if="userInfo.picUrl"
                :src="userInfo.picUrl"
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  opacity: 0;
                "
                :preview-src-list="[userInfo.picUrl]"
              >
              </el-image>
              查看头像</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <div class="nav_list">
          <router-link to="/user/myInfo">
            <div class="item">
              <img
                class="active"
                src="../../assets/user/user_active5.png"
                alt=""
              />
              <img class="normal" src="../../assets/user/user5.png" alt="" />
              <span>我的信息</span>
            </div>
          </router-link>
          <router-link to="/user/mySign">
            <div class="item">
              <img
                class="active"
                src="../../assets/user/user_active1.png"
                alt=""
              />
              <img class="normal" src="../../assets/user/user1.png" alt="" />

              <span>我的报考</span>
            </div>
          </router-link>
          <router-link to="/user/achievement">
            <div class="item">
              <img
                class="active"
                src="../../assets/user/user_active2.png"
                alt=""
              />
              <img class="normal" src="../../assets/user/user2.png" alt="" />
              <span>我的成绩</span>
            </div>
          </router-link>
          <router-link to="/user/certificate">
            <div class="item">
              <img
                class="active"
                src="../../assets/user/user_active3.png"
                alt=""
              />
              <img class="normal" src="../../assets/user/user3.png" alt="" />
              <span>我的证书</span>
            </div>
          </router-link>
          <router-link to="/user/myInvoice">
            <div class="item">
              <img
                class="active"
                src="../../assets/user/user_active4.png"
                alt=""
              />
              <img class="normal" src="../../assets/user/user4.png" alt="" />
              <span>我的发票</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {updateUserInfo} from "@/util/api";
export default {
  data() {
    return {
      result: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    ...mapMutations(["changeUserInfo"]),
    changeFile(file) {
      var This = this;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function () {
        updateUserInfo({
          picHead: this.result,
        }).then((res) => {
          This.userInfo.picUrl = this.result;
          This.$message.success("更换成功");
          This.changeUserInfo(This.userInfo);
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
}
.content {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0 100px;
  display: flex;

  .left,
  .right {
    background-color: #fff;
    border-radius: 10px;
  }
  .left {
    width: 190px;
    min-height: 100vh;
    .header {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .edit_icon {
        position: absolute;
        color: #5586e0;
        right: 50px;
        top: 20px;
        cursor: pointer;
      }
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      p {
        margin-top: 10px;
        text-align: center;
      }
    }
    .item {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        margin-right: 10px;
      }
      .active {
        display: none;
      }
      .normal {
        display: inline-block;
      }
    }
    a {
      font-size: 16px;
      color: #666;
      text-decoration: none;
    }
    .router-link-active {
      font-size: 16px;
      color: #fff;
      .item {
        background-color: #5586e0;
        width: 195px;
        .active {
          display: inline-block;
        }
        .normal {
          display: none;
        }
      }
    }
  }
  .right {
    flex: 1;
    margin-left: 30px;
  }
}
</style>
